<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" >
                      <div class="row">
                        <div class="col-12">
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="remarks"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.remarks_en')}}
                            </template>
                            <b-form-textarea
                                rows="2"
                                id="remarks"
                                v-model="form.comment"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                          </ValidationProvider>
                        </div>
                      </div>
                      <b-row class="text-right">
                        <b-col>
                          <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                          <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { cancelComments } from '../../api/routes'
export default {
  name: 'Details',
  props: ['item'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.cancelation'),
      errors: [],
      loading: false,
      form: {
        comment: ''
      }
    }
  },
  created () {
    if (this.item) {
      this.form.id = this.item.booking.id
      this.form.training_application_id = this.item.id
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(trainingElearningServiceBaseUrl, cancelComments, this.form)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('CommonService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: result.message,
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form-cancel-details')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    }
  }
}
</script>
